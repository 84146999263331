/*
	Ivar Aasengata 10, Speilet
	custom js
	---------
	aut		Simon Dagfinrud
				for Racer AS
	cre 	24. mai 2016
	ver 	1.0
*/


// Scroll based
function anchorScroll() {
	// Smooth scroll to anchor
	var windowHeight = $(window).innerHeight();
	$('a.j-scroll').click(function(){
	    $('html, body').animate({
	        scrollTop: $( $(this).attr('href') ).offset().top - (windowHeight * 0.25)
	    }, 500);
	    return false;
	});
}
function isScrolledIntoView(elem) {
	var viewPortWidth = $(window).outerWidth();
	var viewPortHeight = $(window).height();

	var docViewTop = $(window).scrollTop();
	var docViewBottom = docViewTop + $(window).height();

	var elemTop = $(elem).offset().top + viewPortHeight*0.42;
	var elemBottom = elemTop + $(elem).height();

	return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}
function isScrolled(element,className,pxScrolled) {
	var $document = $(document),
    	$element = $(element);

	$document.scroll(function() {
		if ($document.scrollTop() >= pxScrolled) {

	    	$element.addClass(className);
		} else {
	    	$element.removeClass(className);
		}
	});
}
function addClassWheninView(element) {
	$(element).each(function() {
		if (isScrolledIntoView(this) === true) {
			$(this).addClass('active');
		} else {
			$(this).removeClass('active');
		}
	});
}

// Heights
function setHeight() {
	var windowHeight = $(window).innerHeight();
	$('.j-full').each(function(){
		$(this).height(windowHeight * $(this).attr('data-height'));
	});
}

// Menu
function toggleMenu() {
  $('.menu_button').toggleClass('button--cross');
  $('nav').toggleClass('menu--open');
  $('body').toggleClass('j-no-scroll');
	$('header').toggleClass('menu--open');
}
function hideMenu() {
  $('.menu_button').removeClass("button--cross");
  $('nav').removeClass("menu--open");
  $('body').removeClass('j-no-scroll');
	$('header').removeClass('menu--open');
}
function toggleSidebar() {
	$('.c-appointment').toggleClass('c-appointment--invisible');
	$('.c-appointment').toggleClass('c-appointment--visible');
	$('header').toggleClass('header--sticky');
}
function hideSidebar() {
	$('.c-appointment').addClass('c-appointment--invisible');
	$('.c-appointment').removeClass('c-appointment--visible');
	$('header').removeClass('header--sticky');
}

// Initiate externals
function initHeadroom(headerClass) {

	var windowHeight = $(window).innerHeight();
	// grab an element
	var myElement = document.querySelector(headerClass);
	// construct an instance of Headroom, passing the element
	var headroom  = new Headroom(myElement, {
		"offset": windowHeight * 0.2,
		"tolerance": 5,
		"classes": {
		    "initial": "headroom",
		    "pinned": "headroom--pinned",
		    "unpinned": "headroom--unpinned",
			"top": "headroom--top",
			"notTop": "headroom--not-top",
			"bottom": "headroom--bottom",
			"notBottom": "headroom--fart"
		}
	});
	// initialise
	headroom.init();
}
function loadMap(){
	$('#gmap').gmap3({
		map: {
      options: {
	      maxZoom: 14,
	      mapTypeId: "style1",
				disableDefaultUI: true,
				scaleControl: false,
		    scrollwheel: false
      	}
    	},
    	styledmaptype: {
        id: "style1",
        options: {
            name: "Style 1"
        },
	      styles: [
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#e32020"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2c69de"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#183f8a"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#183f8a"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#9bb9f3"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#9bb9f3"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#9bb9f3"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#9bb9f3"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#376caa"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#1548a8"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#25476f"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
]
	  },
		marker: {
		  address: "Ivar Aasengata 10, 6150 Ørsta, Norway",
		  options: {
		    icon: new google.maps.MarkerImage(
		      "img/map_pointer.svg?v=2",
		      new google.maps.Size(52, 52, "px", "px")
		    )
		  }
		}
	},
	"autofit" );
}
function initSliders(sliderClass) {
	// Initialize all sliders in DOM
	var sliders = [];
	$(sliderClass).each(function(index, element){
	    $(this).addClass('s'+index);
	    var slider = new Swiper('.s'+index, {
			pagination: '.s'+index+' .swiper-pagination',
			paginationClickable: true,
			nextButton: '.s'+index+' .swiper__next',
			prevButton: '.s'+index+' .swiper__prev',
			slidesPerView: 1,
			spaceBetween: 0,
			followFinger: false,
			loop: true,
			effect: "slide"
		});
	  sliders.push(slider);
	});
}


/* --------------------------------------------------- */
/* ------------------  WINDOW LOAD ------------------- */
/* --------------------------------------------------- */

	$(window).load(function() {


		loadMap();

		anchorScroll();
		setHeight();
		initHeadroom('header');



	});

/* --------------------------------------------------- */
/* ------------------ DOCUMENT READY ----------------- */
/* --------------------------------------------------- */

	$(document).ready(function() {


		initSliders('.swiperSlider');
		isScrolled('.timeline__graphic','timeline__graphic--scrolled',50);

		// Toggle and hide menu by keycode and click
		$(document).keydown(function(e){
			if (e.keyCode === 27) {
				hideMenu();
				hideSidebar();
			}
		});

		$('.menu_button').click(function() {
			toggleMenu();
		});
		$('.appointmentButton').click(function() {
			toggleSidebar();
		});


		// Register form
		//	make hidden inputs "name" and "email" included in the mail
		$('#fromName').bind('keypress keyup blur', function() {
			$('#Name').val($(this).val());
		});
		$('#fromEmail').bind('keypress keyup blur', function() {
			$('#Email').val($(this).val());
		});

		$(window).scroll(function() {

			// Add active class to element when in viewport
			addClassWheninView('.timeline__content__item');

		});




	});
